import '../styles/main.css';
import '../styles/tailwind.css';
import React from 'react';
import Head from 'next/head';
import App from 'next/app';

import { appWithTranslation } from 'utils/with-i18next';
import { ErrorTrackerProvider } from 'utils/use-error-tracker';

import 'fontsource-metropolis';
import '@typefaces-pack/typeface-inter';
import TagManager from 'react-gtm-module';
import { PropertiesContextProvider } from 'contexts/PropertiesContext';
import { BuildingContextProvider } from 'contexts/BuildingContext';
import { BookingsContextProvider } from 'contexts/BookingsContext';
import { TicketContextProvider } from 'contexts/TicketContext';
import { UserContextProvider } from 'contexts/UserContext.tsx';
import { ActivitiesContextProvider } from 'contexts/ActivitiesContext';
import { TableAccessContextProvider } from 'contexts/TableAccessContext.tsx';
import { Toaster } from '@/components/ui/toaster.tsx';
import { DashboardContextProvider } from 'contexts/DashboardContext';
import { InsightContextProvider } from 'contexts/InsightsContext';
import { LeniQContextProvider } from 'contexts/LeniQContext';
import { TableauDashboardContextProvider } from 'contexts/TableauDashboardContext';
import { ReportBuilderContextProvider } from 'contexts/ReportBuilderContext';

const tagManagerArgs = {
  id: 'GTM-NHZJKL8',
};

class Srr extends App {
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize(tagManagerArgs);
    }
    // Intercom code snippet
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/jxutt9op';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }
  render() {
    const { Component, pageProps } = this.props;
    const getLayout = Component.getLayout || (page => page);

    return (
      <>
        <Head>
          <title>Data Intelligence Software for Real Estate | Leni</title>
        </Head>
        <ErrorTrackerProvider>
          <UserContextProvider>
            <BuildingContextProvider>
              <PropertiesContextProvider>
                <BookingsContextProvider>
                  <TicketContextProvider>
                    <ActivitiesContextProvider>
                      <DashboardContextProvider>
                        <TableauDashboardContextProvider>
                          <LeniQContextProvider>
                            <InsightContextProvider>
                              <ReportBuilderContextProvider>
                                <TableAccessContextProvider>
                                  {getLayout(<Component {...pageProps}></Component>)}
                                  <Toaster />
                                </TableAccessContextProvider>
                              </ReportBuilderContextProvider>
                            </InsightContextProvider>
                          </LeniQContextProvider>
                        </TableauDashboardContextProvider>
                      </DashboardContextProvider>
                    </ActivitiesContextProvider>
                  </TicketContextProvider>
                </BookingsContextProvider>
              </PropertiesContextProvider>
            </BuildingContextProvider>
          </UserContextProvider>
        </ErrorTrackerProvider>
      </>
    );
  }
}

export default appWithTranslation(Srr);
