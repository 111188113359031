import { MultiSelectGridOptionWithImage } from 'src/types/Forms/Forms';

export enum ReportBuilderKPIMap {
  NEXT_10W_MOVE_OUTS = '2e4a649a-81c1-4236-8bcb-0675cba0f9af',
  NEXT_10W_MOVE_INS = '0db616a7-5688-4b72-9e18-777fbfc6081d',
  NEXT_10W_LEASE_EXPIRATIONS = 'ca1618aa-6223-41a2-8ec2-a53d18ee9638',
  NEXT_10W_CUMULATIVE_EXPOSURE = '799f53b9-6841-41af-8f81-1d655a50199e',
  T6_MONTHLY_LEASE_RENEWAL_RATE = '9ff39b0e-5bf6-4bca-a60b-ed5fcdb2aa33',
  T6_DAILY_OPEN_WORK_ORDERS = '6ae89bd6-563b-4b9c-b788-f7fb5eb42db9',
  T6_DAILY_OCCUPANCY_RATE = 'ba5994f0-130d-42e3-b5e7-a9e9caf1f2cc',
  IN_PLACE_RENT = 'df0a9012-a029-494e-b23c-43d8905f7fb4',
  CURRENT_RENT_COLLECTION = 'ae4f6339-a597-46fb-bc21-4616239111de',
  T12_AVG_$_INCREMENTAL_LEASE_VALUE = '5d503f1a-3104-47d8-8c02-5b9237113870',
  T12_LEASE_TRADE_OUTS_BY_MONTH = 'c19bee33-6fd9-4e08-a201-3fe56c1151e3',
  COMPARITIVE_RENT_COLLECTIONS = 'd67eb820-da90-42e5-80de-f94c6a619d43',
  TENANTS_WITH_A_BALANCE = '918b99ba-64b9-4d48-a99a-c932a3482e75',
  AGING_SUMMARY = '3589f300-1d89-44ae-8c93-cfee639462e5',
  T12_TOTAL_MARKETING_FUNNEL = 'd1b1a8f1-dec7-433f-b038-a484daf62223',
  T12_MARKETING_ACTIVITY = 'affaed5e-0574-4136-9474-bca3d17e01d2',
  T12_MARKETING_ACTIVITY_CONVERSION = '3733ed1c-a71e-4daf-b9c2-9947e96a0cfa',
  OCCUPANCY_BY_PROPERTY = 'a313e02d-5407-4009-93cc-cc3c6d623406',
  T6_DAILY_ECONOMIC_OCCUPANCY = '68509edf-264e-4796-b116-36ce5779da65',
  T6_DAILY_SQFT_OCCUPANCY = '4fef86e7-67d1-4710-982e-ca0386ac4bcb',
  CURRENT_OCCUPANCY_TOTAL = '05f151e0-64f4-4186-b735-49bb3f55129e',
  TOP_10_TRADE_OUT_GAINS = 'd4c9a226-68a3-4fcc-9c28-1483036dea34',
  BOTTOM_10_TRADE_OUT_GAINS = 'c795be15-2738-4ce3-be5d-68ec3ce5eb26',
  T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT = '7dbc891d-61a8-4863-af1a-be682ac2c555',
  T6_LEASE_RENEWAL_RATES_BY_PROPERTY = 'c0f05717-7c02-486e-b6de-ed1ffa50244f',
  CURRENT_TENANT_UNIT_STATUS = '6a7bd6e6-042d-4a01-8aaa-d973b1144515',
  T6_NUM_OF_LEASES_SIGNED = '6d054a01-9304-46b9-8726-d86f7d448dec',
  T6_NUM_OF_LEASE_RENEWALS = '805cad62-e686-4b55-a19f-25de8576ab42',
  T6_NUM_OF_MOVE_INS = '63251d96-7868-45b5-a551-d33fcf7c4bb2',
  T6_NUM_OF_MOVE_OUTS = '2054fefe-0572-47d0-b22f-3730f922afaa',
  T12_MONTHLY_CREATED_WORK_ORDERS = '71f8508b-e6b4-4029-8ba7-60ea7e3541a7',
  T12_MONTHLY_COMPLETED_WORK_ORDERS = '4d2e5612-c7f5-4e92-8ea7-065622735392',
  T12_AVG_DAYS_TO_COMPLETE_WORK_ORDER = '9c817c97-bf34-4487-bc97-2d5c4ea44acd',
  T12_WORK_ORDER_PRIORITY_TRENDS = '43c0ba07-0719-4f65-8aa5-f832c0d03737',
  T12_WORK_ORDER_CATEGORY_TRENDS = 'adc372f8-e367-487c-9700-b1b157317c4c',
}

export enum ChartType {
  BAR_CHART_INTEGER = 'barInteger',
  BAR_CHART_FLOAT = 'barFloat',
  LINE_CHART_INTEGER = 'lineInteger',
  LINE_CHART_FLOAT = 'lineFloat',
  MULTI_BAR_CHART_INTEGER = 'multiBarInteger',
  MULTI_BAR_CHART_FLOAT = 'multiBarFloat',
  MULTI_LINE_CHART_INTEGER = 'multiLineInteger',
  MULTI_LINE_CHART_FLOAT = 'multiLineFloat',
  STACKED_BAR_CHART_INTEGER = 'stackedBarInteger',
  HORIZONTAL_BAR_CHART = 'horizontalBar',
  HORIZONTAL_STACKED_BAR_CHART_INTEGER = 'horizontalStackedBarInteger',
  FUNNEL_CHART = 'funnel',
  TABLE_CHART = 'table',
}

export type ReportBuilderKPIOption = {
  key: string;
  label: string;
  KPIs: MultiSelectGridOptionWithImage[];
};

export const KPIOptions: ReportBuilderKPIOption[] = [
  {
    key: 'executiveSummary',
    label: 'Executive Summary',
    KPIs: [
      {
        id: ReportBuilderKPIMap.NEXT_10W_MOVE_OUTS,
        value: 'Next10WMoveOuts',
        label: 'Next 10W Move Outs',
        imageUrl: '/static/images/graphs/T6_Occupancy_Rate.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.NEXT_10W_MOVE_INS,
        value: 'Next10WMoveIns',
        label: 'Next 10W Move-Ins',
        imageUrl: '/static/images/graphs/T6_Occupancy_Rate.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.NEXT_10W_LEASE_EXPIRATIONS,
        value: 'Next10WLeaseExpirations',
        label: 'Next 10W Lease Expirations',
        imageUrl: '/static/images/graphs/T6_Occupancy_Rate.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.NEXT_10W_CUMULATIVE_EXPOSURE,
        value: 'Next10WCumulativeExposure',
        label: 'Next 10W Cumulative Exposure',
        imageUrl: '/static/images/graphs/T6_Occupancy_Rate.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T6_MONTHLY_LEASE_RENEWAL_RATE,
        value: 'T6MonthlyLeaseRenewalRate',
        label: 'T6 Monthly Lease Renewal Rate',
        imageUrl: '/static/images/graphs/T6_Occupancy_Rate.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.T6_DAILY_OPEN_WORK_ORDERS,
        value: 'T6DailyOpenWorkOrders',
        label: 'T6 Daily Open Work Orders',
        imageUrl: '/static/images/graphs/T6_Open_Work_Orders.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.LINE_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T6_DAILY_OCCUPANCY_RATE,
        value: 'T6DailyOccupancyRate',
        label: 'T6 Daily Occupancy Rate',
        imageUrl: '/static/images/graphs/In_Place_Rent_Num_Bedrooms.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.LINE_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.IN_PLACE_RENT,
        value: 'InPlaceRent',
        label: 'In-Place Rent',
        imageUrl: '/static/images/graphs/This_Months_Rent_Collection.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.MULTI_BAR_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.CURRENT_RENT_COLLECTION,
        value: 'CurrentRentCollection',
        label: 'Current Rent Collection',
        imageUrl: '/static/images/graphs/10W_Exposure.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.MULTI_LINE_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.T12_AVG_$_INCREMENTAL_LEASE_VALUE,
        value: 'T12Avg$IncrementalLeaseValue',
        label: 'T12 Avg $ Incremental Lease Value',
        imageUrl: '/static/images/graphs/T6_Monthly_Lease_Renewal_Rate.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.T12_LEASE_TRADE_OUTS_BY_MONTH,
        value: 'T12LeaseTradeOutsByMonth',
        label: 'T12 Lease Trade-Outs by Month',
        imageUrl: '/static/images/graphs/T12_Lease_Trade_Outs.svg',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
    ],
  },
  {
    key: 'financialPerformance',
    label: 'Financial Performance',
    KPIs: [
      {
        id: ReportBuilderKPIMap.COMPARITIVE_RENT_COLLECTIONS,
        value: 'ComparitiveRentCollections',
        label: 'Comparitive Rent Collections',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.MULTI_LINE_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.TENANTS_WITH_A_BALANCE,
        value: 'TenantsWithABalance',
        label: 'Tenants With A Balance',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.HORIZONTAL_BAR_CHART,
      },
      {
        id: ReportBuilderKPIMap.AGING_SUMMARY,
        value: 'AgingSummary',
        label: 'Aging Summary',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.HORIZONTAL_BAR_CHART,
      },
    ],
  },
  {
    key: 'marketingActivity',
    label: 'Marketing Activity',
    KPIs: [
      {
        id: ReportBuilderKPIMap.T12_TOTAL_MARKETING_FUNNEL,
        value: 'T12TotalMarketingFunnel',
        label: 'T12 Total Marketing Funnel',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.FUNNEL_CHART,
      },
      {
        id: ReportBuilderKPIMap.T12_MARKETING_ACTIVITY,
        value: 'T12MarketingActivity',
        label: 'T12 Marketing Activity',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.MULTI_BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T12_MARKETING_ACTIVITY_CONVERSION,
        value: 'T12MarketingActivityConversion',
        label: 'T12 Marketing Activity Conversion',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.MULTI_LINE_CHART_FLOAT,
      },
    ],
  },
  {
    key: 'occupancyAndLeasing',
    label: 'Occupancy And Leasing',
    KPIs: [
      {
        id: ReportBuilderKPIMap.OCCUPANCY_BY_PROPERTY,
        value: 'OccupancyByProperty',
        label: 'Occupancy by Property',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.TABLE_CHART,
      },
      {
        id: ReportBuilderKPIMap.T6_DAILY_ECONOMIC_OCCUPANCY,
        value: 'T6DailyEconomicOccupancy',
        label: 'T6 Daily Economic Occupancy',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.LINE_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.T6_DAILY_SQFT_OCCUPANCY,
        value: 'T6DailySqFtOccupancy',
        label: 'T6 Daily Sq Ft Occupancy',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.LINE_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.CURRENT_OCCUPANCY_TOTAL,
        value: 'CurrentOccupancyTotal',
        label: 'Current Occupancy - Total',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.TABLE_CHART,
      },
      {
        id: ReportBuilderKPIMap.TOP_10_TRADE_OUT_GAINS,
        value: 'Top10TradeOutGains',
        label: 'Top 10 Tradeout Gains',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.TABLE_CHART,
      },
      {
        id: ReportBuilderKPIMap.BOTTOM_10_TRADE_OUT_GAINS,
        value: 'Bottom10TradeOutGains',
        label: 'Bottom 10 Tradeout Gains',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.TABLE_CHART,
      },
      {
        id: ReportBuilderKPIMap.T6_AVERAGE_GAIN_LOSS_ON_TRADE_OUT,
        value: 'T6AverageGainLossOnTradeOut',
        label: 'T6 Average Gain / Loss on Trade-Out',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.T6_LEASE_RENEWAL_RATES_BY_PROPERTY,
        value: 'T6LeaseRenewalRatesByProperty',
        label: 'T6 Lease Renewal Rates by Property',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.TABLE_CHART,
      },
    ],
  },
  {
    key: 'residentActivity',
    label: 'Resident Activity',
    KPIs: [
      {
        id: ReportBuilderKPIMap.CURRENT_TENANT_UNIT_STATUS,
        value: 'currentTenantUnitStatus',
        label: 'Current Tenant / Unit Status',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.HORIZONTAL_STACKED_BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T6_NUM_OF_LEASES_SIGNED,
        value: 'T6NumOfLeasesSigned',
        label: 'T6 Num of Leases Signed',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T6_NUM_OF_LEASE_RENEWALS,
        value: 'T6NumOfLeaseRenewals',
        label: 'T6 Num of Lease Renewals',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T6_NUM_OF_MOVE_INS,
        value: 'T6NumOfMoveIns',
        label: 'T6 Num of Move-Ins',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T6_NUM_OF_MOVE_OUTS,
        value: 'T6NumOfMoveOuts',
        label: 'T6 Num of Move-Outs',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.BAR_CHART_INTEGER,
      },
    ],
  },
  {
    key: 'wordOrders',
    label: 'Work Orders',
    KPIs: [
      {
        id: ReportBuilderKPIMap.T12_MONTHLY_CREATED_WORK_ORDERS,
        value: 'T12MonthlyCreatedWorkOrders',
        label: 'T12 Monthly Created Work Orders',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.LINE_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T12_MONTHLY_COMPLETED_WORK_ORDERS,
        value: 'T12MonthlyCompletedWorkOrders',
        label: 'T12 Monthly Completed Work Orders',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.LINE_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T12_AVG_DAYS_TO_COMPLETE_WORK_ORDER,
        value: 'T12AvgDaysToCompleteWorkOrder',
        label: 'T12 AVG Days to Complete Work Order',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.LINE_CHART_FLOAT,
      },
      {
        id: ReportBuilderKPIMap.T12_WORK_ORDER_PRIORITY_TRENDS,
        value: 'T12WorkOrderPriorityTrends',
        label: 'T12 Work Order Priority Trends',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.MULTI_BAR_CHART_INTEGER,
      },
      {
        id: ReportBuilderKPIMap.T12_WORK_ORDER_CATEGORY_TRENDS,
        value: 'T12WorkOrderCategoryTrends',
        label: 'T12 Work Order Category Trends',
        imageUrl: '',
        type: 'kpi',
        draggable: true,
        chartType: ChartType.STACKED_BAR_CHART_INTEGER,
      },
    ],
  },
];
